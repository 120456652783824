<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-4">
          <v-tabs align-with-title>
            <v-tab to="/admin/data/artworks">List</v-tab>
            <!-- <v-tab to="/admin/data/artworks/upload">Upload</v-tab>
            <v-tab to="/admin/data/artworks/download">Downloads</v-tab> -->
          </v-tabs>
        <router-view class="view" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  async created () {
  }
}
</script>
